export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#809DDE',
    light: '#e5ebf8'
  },
  secondary: {
    main: '#002345',
    contrastText: '#0A5285',
    light: '#D9EDF7'
  },
  text: {
    primary: '#141B22',
    secondary: '#64748B',
    alternate: '#23B0E7',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#FFFFFF',
    paper: '#FFF',
    default: '#FFF',
    header: '#002345',
    footer: '#F8FBFE',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#809DDE',
    tableSubhead: 'rgba(128, 157, 222, 0.1)',
    tableBorder: '#7D808880',
    tableAlternate: '#FFFFFF',
    alternate: 'rgba(255, 255, 255, 0.2)',
    success: '#DBEFDC',
    error: '#FFEAEA',
    primary: '#002345',
    secondary: '#F8D859',
    light: '#F8FBFE',
    secondaryLight: 'rgba(238, 246, 250, 0.6)',
    disclaimer: '#111111'
  },
  verticalBar: {
    used: '#7BD6D0',
    unused: '#7BD6D01a',
    unlimited: '#C0C0C0',
  }
};